<template>
  <div class="accountset enterpriselist">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <userleft currindex=7 />
        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">数据管理<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb">
                  <div class="btnaddcompany">
                    <el-button type="primary" @click="toadddata"><i
                        class="el-icon-circle-plus-outline el-icon--left"></i>新增数据采集</el-button>
                  </div>
                  <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="数据采集" name="first">
                      <template v-if="measurelistdata!=null">
                        <el-table :data="measurelistdata" border style="width: 100%">
                          <el-table-column prop="realname" label="姓名" align="center"></el-table-column>
                          <el-table-column prop="sex" label="性别"></el-table-column>
                          <el-table-column  label="身高">
                              <template slot-scope="scope">
                              <div class="operation">
                              {{scope.row.height+'CM'}}
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column  label="体重" align="center">
                             <template slot-scope="scope">
                              <div class="operation">
                              {{scope.row.weight+'KG'}}
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column  label="生日" align="center">
                             <template slot-scope="scope">
                              <div class="operation">
                              {{new Date(scope.row.birthday).getFullYear()+"/"+new Date(scope.row.birthday).getMonth()+1+"/"+new Date(scope.row.birthday).getDate()}}
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                              <div class="operation">
                                <span @click="onupdateshipping(scope.row.id)">修改</span>
                                <span class="vertical">|</span>
                                <span type="text" size="mini" @click="onusermeasuredatadel(scope.row.id)">删除</span>
                              </div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </template>
                      <template v-if="measurelistdata==null">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无信息</span>
                        </div>
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import {
  usermeasuredatagetlist,
  usermeasuredatadel
} from '@/api/user';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      activeName: 'first',
      measurelistdata: null,
      verifystatus: 1,
    };
  },
  methods: {
    toadddata() {
      this.$router.push('/user/data/edit');
    },
    onupdateshipping(id) {
      this.$router.push({
        path: '/user/data/edit',
        query: { id: id },
      });
    },
    handleClick(tab, event) {
      var that = this;
    },
    onusermeasuredatadel(id) {
      let form = { id: id };
      usermeasuredatadel(form).then((res) => {
        if (res.data.code === 1) {
          this.onusermeasuredatagetlist();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
   
    onusermeasuredatagetlist() {
      var that = this;
      usermeasuredatagetlist({}).then((res) => {
        if (res.data.code === 1) {
          let tmp = res.data.result;
          for (var i = 0; i < tmp.length; i++) {
            tmp[i].newarea =
              tmp[i].provinceName +
              ' ' +
              tmp[i].cityName +
              ' ' +
              tmp[i].countyName;
            if (tmp[i].isDefault == 1) {
              tmp[i];
            }
          }
          that.measurelistdata = tmp;
        }
      });
    },
  },
  created() {
    this.onusermeasuredatagetlist();
  },
  mounted() {},
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}
.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
.accountset .content-rb .btnaddcompany {
  position: absolute;
  right: 36px;
  z-index: 100;
}
.accountset .content-rb .btnaddcompany button {
  padding: 6px 6px;
  margin-top: 6px;
}
.accountset .content-rb .btnaddcert button {
  margin: 10px 0;
  padding: 6px 6px;
}
.accountset .content-rb .property-box {
  margin-bottom: 20px;
  background-image: linear-gradient(196deg, #72b2ff, #0074ff);
  border-radius: 4px;
}

.operation,
.setasdefault {
  cursor: pointer;
}
.setasdefault >>> .el-button:hover,
.setasdefault >>> .el-button {
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
}
.operation .vertical {
  cursor: default;
  margin: 0 6px;
}
</style>
